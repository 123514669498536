<template>
  <v-card>
    <v-card-title>
      <v-col>
        <span class="headline">Agregar abonos de {{ cliente }}</span>
      </v-col>
    </v-card-title>
    <v-card-text>
      <v-container>
        <template>
          <v-list>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-title>Nuevo abono</v-list-item-title>
              </template>
              <v-list-item>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                      :items="estadoP"
                      item-text="nombre"
                      item-value="nombre"
                      v-model="pagoExtra.pago"
                      placeholder="Tipo de pago"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      :items="mPagos"
                      item-text="nombre"
                      item-value="nombre"
                      v-model="pagoExtra.modoPago"
                      placeholder="Modo de pago"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <money
                      class="money"
                      v-model="pagoExtra.valorCuota"
                      label="Cantidad"
                      v-bind="money"
                    ></money>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="pagoExtra.fechaPago | formatDate"
                          label="Fecha de pago"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="ES"
                        v-model="pagoExtra.fechaPago"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-list-item>
              <v-row>
                <v-col cols="12" md="5" v-bind:style="{ 'margin-top': '18px' }">
                  <label>
                    Cargar comprobante:
                    <input
                      id="files"
                      ref="fileE"
                      @change="getFileE"
                      type="file"
                    />
                  </label>
                </v-col>
                <v-col cols="12" md="4" v-if="base64E != null">
                  <v-text-field
                    v-model="pagoExtra.noComprobante"
                    label="No. de comprobante"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4" v-if="base64E == null"></v-col>
                <v-col cols="12" md="1" v-bind:style="{ 'margin-top': '18px' }">
                  <v-btn
                    :color="buscando == false ? 'primary' : 'grey'"
                    small
                    @click="buscando == false ? nuevaCuota(pagoExtra) : ''"
                    >Guardar</v-btn
                  >
                </v-col>
              </v-row>
            </v-list-group>
          </v-list>
        </template>
        <template>
          <v-row v-bind:style="{ 'justify-content': 'space-around' }">
            <v-col cols="12" sm="2" md="3">
              <h4>Valor acordado: {{ valorAcordado | currency }}</h4>
            </v-col>
            <v-col cols="12" sm="2" md="3">
              <h4 class="primary--text">
                Valor recaudado : {{ valorRecaudado | currency }}
              </h4>
            </v-col>
            <v-col cols="12" sm="2" md="3">
              <h4 class="red--text">
                Valor Restante: {{ valorRestante | currency }}
              </h4>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="cerrar">cerrar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import data from "../Tools/data";

export default {
  props: [
    "cliente",
    "ventaId",
    "valorAcordado",
    "valorRecaudado",
    "valorRestante",
  ],
  data() {
    return {
      pagoExtra: {
        modoPago: null,
        valorCuota: null,
        pago: null,
        estado: null,
        fechaPago: null,
      },
      buscando: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "$ ",
        suffix: "",
        precision: 1,
        masked: false,
      },
      base64E: null,
    };
  },
  async created() {
    // MODOS DE PAGOS
    if (this.$store.state.modosPago.length == 0) {
      this.mPagos = await data.dataList(
        "/modosPago",
        "lista",
        "listar",
        "modosPago"
      );
    } else {
      this.mPagos = this.$store.state.modosPago;
    }
    // ESTADOS DE PAGOS
    if (this.$store.state.estadosPagos.length == 0) {
      this.estadoP = await data.dataList(
        "/estadosPago",
        "lista",
        "listar",
        "estadosPagos"
      );
    } else {
      this.estadoP = this.$store.state.estadosPagos;
    }
  },

  computed: {
    mPagos: function () {
      var info = [];
      for (var m = 0; m < this.$store.state.modosPago.length; m++) {
        info.push(this.$store.state.modosPago[m]["nombre"]);
      }
      return info;
    },
    estadoP: function () {
      var info = [];
      for (var ep = 0; ep < this.$store.state.estadosPagos.length; ep++) {
        info.push(this.$store.state.estadosPagos[ep]["nombre"]);
      }
      return info;
    },
  },

  methods: {
    getFileE() {
      this.filesE = [];
      this.filesE = this.$refs.fileE.files[0];
      this.convertFileE(this.filesE);
    },

    convertFileE(d) {
      var url = d;
      var reader = new FileReader();
      reader.readAsDataURL(url);
      reader.onload = () => {
        this.base64E = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },

    nuevaCuota() {
      this.buscando = true;
      if (this.buscando == true) {
        var fecha2 = this.$moment(this.pagoExtra.fechaPago).add(5, "h");
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "pago_extra");
            fd.append("idVenta", this.ventaId);
            fd.append("cantidad", this.pagoExtra.valorCuota);
            fd.append("modoPago", this.pagoExtra.modoPago);
            fd.append(
              "tipoPago",
              this.pagoExtra.pago == null ? "pendiente" : this.pagoExtra.pago
            );
            fd.append("soportePago", this.base64E);
            fd.append("noComprobante", this.pagoExtra.noComprobante);
            fd.append("fechaPago", fecha2);
            axios.post(host + "/pagos", fd).then((result) => {
              let data = result.data;
              this.buscando = false;
              if (data.text == "OK") {
                this.pagoExtra = {
                  modoPago: null,
                  valorCuota: null,
                  pago: null,
                  estado: null,
                  fechaPago: null,
                };
                this.$emit("eventosNa", true);
              } else {
                this.$alert(data.text);
              }
            });
          })
          .catch((error) => {
            this.$alert("Error " + error.message);
          });
      }
    },
    cerrar() {
      this.$emit("eventosNa", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-money {
  outline: none !important;
  border: none;
  border-bottom: 1px solid rgb(118, 118, 118);
  padding: 4px 0px;
  margin-top: 18px;
  color: grey;
  font-size: 18px;
  width: 100%;
}
</style>